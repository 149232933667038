<template>
  <div id="download_app_page">
    <div class="container">
      <span v-if="os == 'Android' || os == 'iOS'" class="page-title">Download App</span>

      <div v-if="os == 'iOS'">
        <p>In order to download the app for iOS, please follow the instructions below.</p>

        <br>
        <p><b>1.</b> Make sure this link (<a class="highlighted" href="https://app.sharo.io/download">app.sharo.io/download</a>) is opened in Safari.</p>
        <img class="share-img" :src="publicPath + 'img/extra/safari.png'" alt="">

        <br>
        <br>
        <p><b>2.</b> Click the share icon in the browser.</p>
        <img class="share-img" :src="publicPath + 'img/extra/ios_download_1.png'" alt="">

        <br>
        <br>
        <p><b>3.</b> Select the option 'Add to Home Screen'.</p>
        <img class="share-img" :src="publicPath + 'img/extra/ios_add_to_homepage_small.png'" alt="">

        <br>
        <br>
        <p><b>4.</b> Click the 'Add' button and your app is installed in your home screen.</p>
        <img :src="publicPath + 'img/extra/add_to_home.jpg'" class="share-img">
      </div>

      <div v-if="os == 'Android'">
        <p>In order to download the app for Android, please click the link bellow:</p>
        <span style="display: block;margin-top: 10px"></span>
        <a class="highlighted" href="https://play.google.com/store/apps/details?id=io.sharo.app">Click here to download app</a>
      </div>

      <div v-if="os != 'Android' && os != 'iOS'" class="all-options">
        <div class="ios-option">
          <span class="title-option">How to download on iPhone</span>
          <br>
          <p><b>1.</b> Make sure this link (<a class="highlighted" href="https://app.sharo.io/download">app.sharo.io/download</a>) is opened in Safari.</p>
          <img class="share-img" :src="publicPath + 'img/extra/safari.png'" alt="">

          <br>
          <br>
          <p><b>2.</b> Click the share icon in the browser.</p>
          <img class="share-img" :src="publicPath + 'img/extra/ios_download_1.png'" alt="">

          <br>
          <br>
          <p><b>3.</b> Select the option 'Add to Home Screen'.</p>
          <img class="share-img" :src="publicPath + 'img/extra/ios_add_to_homepage_small.png'" alt="">

          <br>
          <br>
          <p><b>4.</b> Click the 'Add' button and your app is installed in your home screen.</p>
          <img :src="publicPath + 'img/extra/add_to_home.jpg'" class="share-img">
        </div>
        <div class="android-option">
          <span class="title-option">How to download on Android</span>
          <p><b>1.</b> Go to the Play Store on your phone and search for 'Sharo Mobility'.</p>
          <img class="share-img" :src="publicPath + 'img/extra/search_app_android.jpeg'" alt="">
          <br>
          <br>
          <p><b>2.</b> Click on the one shown above and click 'Install'.</p>
          <img :src="publicPath + 'img/extra/app_android.jpeg'" alt="" class="share-img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    os: null,
    publicPath: process.env.BASE_URL
  }),

  mounted() {
    this.os = this.getOperatingSystem();      

    if (this.os == 'Android') {
      return window.location = 'https://play.google.com/store/apps/details?id=io.sharo.app';
    }

    if (this.os == 'iOS') {
      return;
    }
  }
}
</script>

<style lang="scss" scoped>
  #download_app_page > .container {
    
  }

 .share-img {
   max-width: 400px;
   width: 100%;
   padding: 10px;
   border: 1px solid rgba(0,0,0,0.1);
   margin-top: 15px;
   border-radius: 5px;
 }

 @media (max-width: 700px) {
   .all-options {
     flex-wrap: wrap;

     & > div {
       border: 0 !important;
     }
   }
 }

 .all-options {
   display: flex;
   justify-content: center;
   margin: 0 -50px;

   & > div {
     float: 1;
     width: 100%;
     min-height: 20px;
     padding: 20px 50px;
     margin-bottom: 20px;
   }

   .ios-option {
     border-right: 1px solid rgba(0,0,0,0.1);
    }

  .android-option {

  }

  .title-option {
    display: block;
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 400;
  }
 }
</style>