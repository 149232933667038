var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "download_app_page" } }, [
    _c("div", { staticClass: "container" }, [
      _vm.os == "Android" || _vm.os == "iOS"
        ? _c("span", { staticClass: "page-title" }, [_vm._v("Download App")])
        : _vm._e(),
      _vm.os == "iOS"
        ? _c("div", [
            _c("p", [
              _vm._v(
                "In order to download the app for iOS, please follow the instructions below."
              )
            ]),
            _c("br"),
            _vm._m(0),
            _c("img", {
              staticClass: "share-img",
              attrs: { src: _vm.publicPath + "img/extra/safari.png", alt: "" }
            }),
            _c("br"),
            _c("br"),
            _vm._m(1),
            _c("img", {
              staticClass: "share-img",
              attrs: {
                src: _vm.publicPath + "img/extra/ios_download_1.png",
                alt: ""
              }
            }),
            _c("br"),
            _c("br"),
            _vm._m(2),
            _c("img", {
              staticClass: "share-img",
              attrs: {
                src: _vm.publicPath + "img/extra/ios_add_to_homepage_small.png",
                alt: ""
              }
            }),
            _c("br"),
            _c("br"),
            _vm._m(3),
            _c("img", {
              staticClass: "share-img",
              attrs: { src: _vm.publicPath + "img/extra/add_to_home.jpg" }
            })
          ])
        : _vm._e(),
      _vm.os == "Android"
        ? _c("div", [
            _c("p", [
              _vm._v(
                "In order to download the app for Android, please click the link bellow:"
              )
            ]),
            _c("span", {
              staticStyle: { display: "block", "margin-top": "10px" }
            }),
            _c(
              "a",
              {
                staticClass: "highlighted",
                attrs: {
                  href:
                    "https://play.google.com/store/apps/details?id=io.sharo.app"
                }
              },
              [_vm._v("Click here to download app")]
            )
          ])
        : _vm._e(),
      _vm.os != "Android" && _vm.os != "iOS"
        ? _c("div", { staticClass: "all-options" }, [
            _c("div", { staticClass: "ios-option" }, [
              _c("span", { staticClass: "title-option" }, [
                _vm._v("How to download on iPhone")
              ]),
              _c("br"),
              _vm._m(4),
              _c("img", {
                staticClass: "share-img",
                attrs: { src: _vm.publicPath + "img/extra/safari.png", alt: "" }
              }),
              _c("br"),
              _c("br"),
              _vm._m(5),
              _c("img", {
                staticClass: "share-img",
                attrs: {
                  src: _vm.publicPath + "img/extra/ios_download_1.png",
                  alt: ""
                }
              }),
              _c("br"),
              _c("br"),
              _vm._m(6),
              _c("img", {
                staticClass: "share-img",
                attrs: {
                  src:
                    _vm.publicPath + "img/extra/ios_add_to_homepage_small.png",
                  alt: ""
                }
              }),
              _c("br"),
              _c("br"),
              _vm._m(7),
              _c("img", {
                staticClass: "share-img",
                attrs: { src: _vm.publicPath + "img/extra/add_to_home.jpg" }
              })
            ]),
            _c("div", { staticClass: "android-option" }, [
              _c("span", { staticClass: "title-option" }, [
                _vm._v("How to download on Android")
              ]),
              _vm._m(8),
              _c("img", {
                staticClass: "share-img",
                attrs: {
                  src: _vm.publicPath + "img/extra/search_app_android.jpeg",
                  alt: ""
                }
              }),
              _c("br"),
              _c("br"),
              _vm._m(9),
              _c("img", {
                staticClass: "share-img",
                attrs: {
                  src: _vm.publicPath + "img/extra/app_android.jpeg",
                  alt: ""
                }
              })
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("1.")]),
      _vm._v(" Make sure this link ("),
      _c(
        "a",
        {
          staticClass: "highlighted",
          attrs: { href: "https://app.sharo.io/download" }
        },
        [_vm._v("app.sharo.io/download")]
      ),
      _vm._v(") is opened in Safari.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("2.")]),
      _vm._v(" Click the share icon in the browser.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("3.")]),
      _vm._v(" Select the option 'Add to Home Screen'.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("4.")]),
      _vm._v(
        " Click the 'Add' button and your app is installed in your home screen."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("1.")]),
      _vm._v(" Make sure this link ("),
      _c(
        "a",
        {
          staticClass: "highlighted",
          attrs: { href: "https://app.sharo.io/download" }
        },
        [_vm._v("app.sharo.io/download")]
      ),
      _vm._v(") is opened in Safari.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("2.")]),
      _vm._v(" Click the share icon in the browser.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("3.")]),
      _vm._v(" Select the option 'Add to Home Screen'.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("4.")]),
      _vm._v(
        " Click the 'Add' button and your app is installed in your home screen."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("1.")]),
      _vm._v(
        " Go to the Play Store on your phone and search for 'Sharo Mobility'."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("2.")]),
      _vm._v(" Click on the one shown above and click 'Install'.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }